@font-face {
  font-family: "iconfont"; /* Project id 4614571 */
  src: url('//at.alicdn.com/t/c/font_4614571_nnq8e6jqoib.woff2?t=1720506378236') format('woff2'),
       url('//at.alicdn.com/t/c/font_4614571_nnq8e6jqoib.woff?t=1720506378236') format('woff'),
       url('//at.alicdn.com/t/c/font_4614571_nnq8e6jqoib.ttf?t=1720506378236') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blogger:before {
  content: "\ebef";
}

.icon-calendar-alt:before {
  content: "\e600";
}

.icon-circle-half-stroke:before {
  content: "\e601";
}

.icon-gear:before {
  content: "\e602";
}

.icon-envelope:before {
  content: "\e603";
}

.icon-rss:before {
  content: "\e604";
}

.icon-bilibili:before {
  content: "\e605";
}

.icon-GitHub:before {
  content: "\e6ad";
}
